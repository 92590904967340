@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap);
* {
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  background-color: #fcf3f6;
}

h1 {
  font-size: 2.5rem;
  color: #2c292b;
}

h2 {
  font-size: 1.5rem;
  color: #2c292b;
}

.MeetupItem_item__16n8F {
    margin: 1rem 0;
  }
  
  .MeetupItem_image__1NY8N {
    width: 100%;
    height: 20rem;
    overflow: hidden;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }
  
  .MeetupItem_image__1NY8N img {
    width: 100%;
    object-fit: cover;
  }
  
  .MeetupItem_content__3JjUt {
    text-align: center;
    padding: 1rem;
  }
  
  .MeetupItem_content__3JjUt h3 {
    font-size: 1.25rem;
    color: #2c292b;
  }
  
  .MeetupItem_actions__i7I9f {
    padding: 1.5rem;
    text-align: center;
  }
  
  .MeetupItem_actions__i7I9f button {
    font: inherit;
    cursor: pointer;
    color: #77002e;
    border: 1px solid #77002e;
    background-color: transparent;
    padding: 0.5rem 1.5rem;
    border-radius: 4px;
  }
  
  .MeetupItem_actions__i7I9f button:hover,
  .MeetupItem_actions__i7I9f button:active {
    background-color: #ffe2ed;
  }
  
.Card_card__3_jzl {
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.MeetupList_list__3WMaa {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
.NewMeetupForm_form__1Qsl3 {
  padding: 1rem;
}

.NewMeetupForm_control__xuwh5 {
  margin-bottom: 0.5rem;
}

.NewMeetupForm_control__xuwh5 label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.NewMeetupForm_control__xuwh5 input,
.NewMeetupForm_control__xuwh5 textarea {
  display: block;
  font: inherit;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 0.25rem;
  width: 100%;
}

.NewMeetupForm_actions__tMvWn {
  margin-top: 1rem;
  text-align: right;
}

.NewMeetupForm_actions__tMvWn button {
  font: inherit;
  cursor: pointer;
  background-color: #77002e;
  color: white;
  padding: 0.5rem 1.5rem;
  border: 1px solid #77002e;
  border-radius: 4px;
  font-weight: bold;
}

.NewMeetupForm_actions__tMvWn button:hover,
.NewMeetupForm_actions__tMvWn button:active {
  background-color: #a50e48;
  border-color: #a50e48;
}

.MainNavigation_header__pcWBd {
    width: 100%;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #77002e;
    padding: 0 10%;
  }
  
  .MainNavigation_logo__3mTft {
    font-size: 2rem;
    color: white;
    font-weight: bold;
  }
  
  .MainNavigation_header__pcWBd ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: baseline;
  }
  
  .MainNavigation_header__pcWBd li {
    margin-left: 3rem;
  }
  
  .MainNavigation_header__pcWBd a {
    text-decoration: none;
    font-size: 1.5rem;
    color: #fcb8d2;
  }
  
  .MainNavigation_header__pcWBd a:hover,
  .MainNavigation_header__pcWBd a:active,
  .MainNavigation_header__pcWBd a.MainNavigation_active__oN3Ka {
    color: white;
  }
  
  .MainNavigation_badge__QYsXO {
    background-color: #cc2062;
    color: white;
    border-radius: 12px;
    padding: 0 1rem;
    margin-left: 0.5rem;
  }

.Layout_main__auk_r {
  margin: 3rem auto;
  width: 90%;
  max-width: 40rem;
}

